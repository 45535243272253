<template>
    <div class="nurseTypeComponentParentClass">
        <p style="margin-bottom: 14px;">Nurse Type</p>
        <div class="nurseTypeContainer">
            <v-card flat
                :class="(selectedNurseType.nurseType === nurseType.nurseType ? 'selectedNurseCardStyle' + calculateDisableEnableClass(nurseType.nurseType): 'nurseCardStyle' + calculateDisableEnableClass(nurseType.nurseType))"
                v-for="nurseType in nurseTypes" :key="nurseType.nurseType" @click="nurseSelectionHandler(nurseType)">
                <div class="nurseImageContainer">
                    <img class="" :src="(selectedNurseType.nurseType === nurseType.nurseType) ? nurseType.selected_icon : nurseType.unSelected_icon" />
                </div>
                <h5>{{ nurseType.nurseType }}</h5>
                <span class="comingSoon" v-if="nurseType.nurseType === 'On Call Nurse'">Coming Soon</span>
            </v-card>
        </div>
        <div style="">
            <v-card class="nurseDescriptionContainer" flat>
                <h3 class=" servicesOfNurseHeading">Services of {{ selectedNurseType.nurseType }}</h3>
                <p style="margin: 0;">
                    {{ selectedNurseType.overallDescription }}
                </p>
                <ul class="descriptionListStyle">
                    <li v-for="description in descriptionData" :key="description" >{{ description }}</li>
                </ul>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {
    name: 'nurseTypes',
    props: ['nurseTypesProp'],
    data() {
        return {
            nurseTypes: [],
            selectedNurseType: {},
            descriptionDataRefractured: null
        }
    },
    methods: {
        nurseSelectionHandler(nurseType) {
            this.selectedNurseType = nurseType
            this.$store.dispatch("changeNurseType",nurseType) 
        },
        calculateDisableEnableClass(nurseType) {
            if (nurseType === 'On Call Nurse') {
                return ' disableClick'
            } else {
                return ''
            }
        }
    },
    mounted() {
        this.nurseTypes = this.$props.nurseTypesProp
        let selectedNurseTypeInStore = this.$store.state.nurseBooking.nurseType
        this.selectedNurseType = (typeof(selectedNurseTypeInStore) !== 'object') ? this.nurseTypes[0] : selectedNurseTypeInStore 
        this.$store.dispatch('changeNurseType', this.selectedNurseType)
        this.descriptionDataRefractured = this.descriptionData
    },
    computed: {
        descriptionData() { 
            if(this.selectedNurseType === undefined) return
            return this.selectedNurseType.description.split('/n');
        },
        descriptionPara() {
            if(this.selectedNurseType === undefined) return
            return this.selectedNurseType.overallDescription;
        
        }
    }

}
</script>
<style scoped>
.nurseTypeContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
    /* padding: 5px; */
    /* margin-top: 12px; */
    margin-bottom: 14px;
}
.disableClick {
    pointer-events: none;
}

.nurseCardStyle {
    display: flex;
    padding: 18px 10px 16px 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 0.749px solid #E0E0E0 !important;
    background: #FFF !important;
    color: #828282 !important;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17.985px; /* 149.874% */
}

.selectedNurseCardStyle {
    display: flex;
    padding: 18px 10px 16px 10px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 8px;
    background:#1467BF !important;
    color: white !important;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17.985px; /* 149.874% */
}


.nurseIconStyle {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nurseDescriptionContainer {
    display: flex;
    padding: 16px 16px 18px 16px;
    flex-direction: column;
    justify-content: center;
    gap: 18px;
    align-self: stretch;
    border-radius: 8px;
    background: #F4F6FA !important;
    margin-top: 14px;
    }

.descriptionListStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    gap: 6px;
    padding: 10px;
    padding-top: 0;
}

.nurseTypeComponentParentClass p{
    text-align: left;
    /* padding: 0px 24px 0px 24px; */
    color: #828282 !important;
}

.descriptionListStyle li{
    color: #828282 !important
}
.servicesOfNurseHeading {
    color: #333 !important;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 24px; /* 150% */
}

.comingSoon{
    font-size: 12px !important; 
    margin: 0; 
    padding: 0; 
    color: red !important;
}
</style>