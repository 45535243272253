<template>
    <div class="slotTypeParentClass" style="padding-top: 20px;">
        <div style="padding: 0px 14px ; margin-top: 15px; display: flex; flex-direction: column; align-items: flex-start; gap: 8px; color: #828282 !important;">
            <span>Select Speciality</span>
            <v-autocomplete v-if="$i18n.locale == 'en'" style="width: 100%; text-align: left;"
            class="specialty-autocomplete"
            v-model="selectedSpeciality"
            @change = "nurseSpecialtyHandler()"
            :items="specialtyDropDownValues"
            item-value="skillId"
            item-text="skillName"
            outlined
            return-object
            ></v-autocomplete>            
            <v-autocomplete v-else style="width: 100%; text-align: left;"
            class="specialty-autocomplete"
            v-model="selectedSpeciality"
            @change = "nurseSpecialtyHandler()"
            :items="specialtyDropDownValues"
            item-value="skillId"
            item-text="skillNameMM"
            outlined
            return-object
            ></v-autocomplete>
        </div>
        <p class="ma-0">Choose Slot</p>
        <div class="slotContainer">
            <v-card flat v-if="shiftTypeFlag"
                :class="((selectedShiftDetails == slotDetail.shiftName || selectedShiftDetails.shiftName == slotDetail.shiftName ) ? 'shiftContainer_selected' : 'shiftContainer')"
                v-for="slotDetail in slotDetails" :key="slotDetail.shiftName" @click="shiftSelectionHandler(slotDetail)">
                <img :src="slotDetail.shiftIcon">
                <div
                    :class="(selectedShiftDetails == slotDetail.shiftName || selectedShiftDetails.shiftName == slotDetail.shiftName ? 'shiftDetailsStyle_selected' : 'shiftDetailsStyle')">
                    <h5>{{ slotDetail.shiftName }}</h5>
                    <span>{{ slotDetail.shiftTimings }}</span>
                </div>
            </v-card>
        </div>
        <p style="margin-top: 14px; margin-bottom: 14px;">Select Start & End Date</p>
        <div class="datePickerContainer">
            <v-date-picker v-model="selectedDates" no-title full-width range :min="allowedDatesValue[0]"
                @input="dateSelectionHandler()" :max="allowedDatesValue[allowedDatesValue.length - 1]" />
        </div>
    </div>
</template>
<script>
import moment from 'moment'
import { mapState } from 'vuex';
export default {
    name: 'slotType',
    props: ['shiftDetails', 'currentDate', 'specialtyTypes'],
    data() {
        return {
            slotDetails: [],
            selectedShiftDetails: "Morning Shift",
            selectedDates: [],
            currentDateValue: Date.now().date,
            allowedDatesValue: [],
            slotDetailRefresher: 0,
            shiftTypeFlag: true,
            morningSlotDisabled: false,
            morningSlotDisablerFlag: false,
            counter: 0,
            selectedSpeciality: '',
            specialtyDropDownValues: []
        }
    },
    mounted() {
        this.slotDetails = this.$props.shiftDetails;
        let shiftTypeInStore = this.$store.state.nurseBooking.shiftType;
        for(let shiftDetail of this.$props.shiftDetails){
            if(shiftDetail.shiftName === shiftTypeInStore){
                this.selectedShiftDetails = shiftDetail.shiftName
                break
            }else{
                this.selectedShiftDetails = this.slotDetails[0];
            }
        }
        if(this.$store.state.nurseBooking.selectedDates){
            this.selectedDates = this.$store.state.nurseBooking.selectedDates
        }
        let selectedNurseType = this.$store.state.nurseBooking.nurseType.nurseType;
        this.specialtyDropDownValues = this.$props.specialtyTypes[selectedNurseType.toUpperCase().replaceAll(/\s/g,'')];
        this.selectedSpeciality = this.$store.state.nurseBooking.nurseSpecialtyDetails;
        this.allowedDatesValue = this.allowedDates();
    },
    methods: {
        shiftSelectionHandler(shiftDetails) {
            this.shiftTypeFlag = false
            this.selectedShiftDetails = (shiftDetails.shiftName) ? shiftDetails.shiftName : "Morning Shift"
            this.$store.dispatch('changeShiftType', this.selectedShiftDetails)
            this.shiftTypeFlag = true
        },
        getNextSlotDate(currentDate) {
            const morningStartHour = 7; //7am
            const nightStartHour = 21; // 9PM
            console.log(currentDate);
            let nextSlotDate = currentDate
            if (currentDate.getHours() > morningStartHour) {
                nextSlotDate.setDate(nextSlotDate.getDate() + 1);
                nextSlotDate.setHours(nightStartHour, 0, 0, 0);
            }
            else if (currentDate.getHours() > nightStartHour) {
                nextSlotDate.setDate(nextSlotDate.getDate() + 2);
                nextSlotDate.setHours(morningStartHour, 0, 0, 0);
            }
            return nextSlotDate;
        },
        allowedDates() {
            const dates = [];
            const today = new Date();
            const slotCheck = this.getNextSlotDate(today)
            console.log(slotCheck)
            for (let i = 0; i < 30; i++) {
                const date = new Date(today);
                date.setDate(today.getDate() + i);
                const yyyy = date.getFullYear();
                const mm = String(date.getMonth() + 1).padStart(2, '0');
                const dd = String(date.getDate()).padStart(2, '0');
                dates.push(`${yyyy}-${mm}-${dd}`);
            }
            return dates;
        },
        dateSelectionHandler() {
            let startDate = this.selectedDates[0]
            let endDate = this.selectedDates[this.selectedDates.length - 1]
            this.selectedDates = this.generateDateRange(startDate, endDate)
            if (startDate <= endDate) {
                this.selectedDates = this.generateDateRange(startDate, endDate)
                console.log(this.selectedDates)
                this.$store.dispatch('changeSelectedDates', this.selectedDates)
            } else if (startDate > endDate) {
                this.selectedDates = []
                window.alert("invalid date selection")
            }
        },
        endDatesValidation(date) {
            if (this.selectedDates[0]) {
                return new Date(date) > new Date(this.selectedDates[0]);
            }
            return true;
        },
        generateDateRange(startDate, endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const selectedDates = [];


            while (start.isSameOrBefore(end)) {
                selectedDates.push(start.format('YYYY-MM-DD'));
                start.add(1, 'days');
            }
            return selectedDates;
        },
        nurseSpecialtyHandler(){
            this.$store.dispatch('changeNurseSpecialty', this.selectedSpeciality);
        }
    },
    computed: {
        ...mapState({
            nurseType: state => state.nurseBooking.nurseType
        }),
    },
    watch: {
        selectedDates(newValue) {
            if (newValue.includes(new Date()) && this.morningSlotDisabled) {
                this.morningSlotDisablerFlag = true
            }
        },
        nurseType(newValue){
            this.specialtyDropDownValues = this.$props.specialtyTypes[newValue.nurseType.toUpperCase().replaceAll(/\s/g,'')];
            this.selectedSpeciality = '';
            this.$store.dispatch('changeNurseSpecialty', this.selectedSpeciality);
        }
    }
}
</script>
<style scoped>
.shiftContainer {
    display: flex;
    padding: 10px 12px;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    border: 0.5px solid #E0E0E0 !important;
    background: #FFF !important;
    color: #828282 !important;
}

.shiftContainer_selected {
    display: flex;
    padding: 10px 12px;
    flex: 1 0 0;
    gap: 8px;
    border-radius: 8px;
    border: 0.5px solid #48ACEF !important;
    background: rgba(72, 172, 239, 0.10) !important;
    color: #48ACEF !important;
}

.shiftDetailsStyle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: #828282 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.shiftDetailsStyle_selected {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    color: #48ACEF !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.slotTypeParentClass p {
    text-align: left;
    padding-left: .9rem;
    margin-top: 14px;
    margin-bottom: 0;
    color: #828282 !important;
}

.slotTypeParentClass {
    padding-top: 0 !important;
}

.slotContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    padding: 14px;
}


.shiftDetailsStyle span {
    color: #828282 !important;
    font-size: 12px;
    font-weight: 400;
    color: #828282 !important;
    text-overflow: ellipsis;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
}

.shiftDetailsStyle_selected span {
    font-size: 12px;
    font-weight: 400;
text-overflow: ellipsis;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 18px; /* 150% */
}

.datePickerStyle {
    width: 100%;
    height: 100%;
}

.datePickerContainer {
    border-radius: 5px;
    border: 1px solid #E0E0E0 !important;
    margin: 14px !important;
}

.disabledCard {
    opacity: 0.5;
    pointer-events: none;
}

.disabledContent {
    opacity: 0.5;
    pointer-events: none;
}
</style>
<style>
.v-date-picker-table .v-btn.v-btn--active {
    background-color: #1467BF !important;
    /* color: #fff !important; */
}

.v-btn--rounded {
    border-radius: 4px !important;
}

.v-autocomplete__content{
    text-align: left;
}
</style>